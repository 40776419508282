<template>
  <div>
    <!-- <div class="register-section">
      <div class="columns">
        <div class="column is-12">
          <div class="columns">
            <div class="column is-half text-center">
              <img
                class="lazboy-logo"
                src="@/assets/beyound-the-recliner.png"
                alt="LA-Z Boy"
              />
            </div>
          </div>
          <div class="columns">
            <div class="column is-half text-center">
              <h2 class="text-h6 text-lg-h6 title-color font-weight-light">
                Please join us for an exclusive event of
              </h2>
              <h1 class="font-weight-bold product-launching title-color">
                "NEW PRODUCT LAUNCHING"
              </h1>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half text-center display-1 text-h6">
              <h4 class="font-weight-bold">Live streaming at</h4>
              <a href="/live">
                <h4 class="font-weight-bold website-color">
                  www.lazboyasiaconf.com
                </h4>
              </a>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half text-center">
              <p class="date">
                <span class="font-weight-bold"
                  >January
                  <span class="display-2 text-h5 font-weight-bold"
                    >27<sup>th</sup></span
                  >, 2022</span
                >
                <br />
                <span class="time font-weight-normal"
                  >1.45 - 3.15 PM <span>(BKK Time)</span></span
                >
              </p>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half text-center">
              <div class="box-border">
                <countdown
                  :time="time"
                  :interval="100"
                  tag="div"
                  class="countdown-container"
                >
                  <template slot-scope="props">
                    <div class="d-flex justify-space-between">
                      <div class="d-block">
                        <span class="text-lg-h3 text-h5 font-weight-bold">{{
                          props.days > 1
                            ? props.days * 24
                            : props.days == 1
                            ? props.hours + 24
                            : props.hours
                        }}</span>
                        <div>Hours</div>
                      </div>
                      <span class="text-lg-h3 text-h6">:</span>
                      <div class="d-block">
                        <span class="text-lg-h3 text-h6 font-weight-bold">{{
                          props.minutes
                        }}</span>
                        <div>Minutes</div>
                      </div>
                      <span class="text-lg-h3 text-h6">:</span>
                      <div class="d-block">
                        <span class="text-lg-h3 text-h6 font-weight-bold">{{
                          props.seconds
                        }}</span>
                        <div>Seconds</div>
                      </div>
                    </div>
                  </template>
                </countdown>
              </div>
            </div>
          </div>
          <div class="columns">
            <div class="column is-half text-center">
              <router-link to="/register">
                <img
                  class="register-btn"
                  :src="require('@/assets/register2-btn.png')"
                  alt="register"
                />
              </router-link>
              <router-link to="/live">
                <img
                  class="register-btn"
                  :src="require('@/assets/join-btn.png')"
                  alt="register"
                />
              </router-link>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="container">
      <div class="columns">
        <div class="column is-12">
          <v-img :src="require('@/assets/home/hq.png')" alt="hq" />
        </div>
      </div>
      <div class="columns">
        <div class="column is-12">
          <h4 class="text-xs-h5 text-lg-h4 title-color pb-4">OUR MISSION</h4>
          <p>
            “La-Z-Boy enriches people’s lives by transforming houses into homes”
          </p>
          <p>
            Our mission is to be the leading global provider of comfortable,
            personalized and stylish home furnishings. Our people are passionate
            about our customers. Creating quality products and investing in
            their communities.
          </p>
        </div>
      </div>
      <div class="columns is-mobile is-multiline">
        <div class="column is-4-desktop is-half-mobile">
          <v-img :src="require('@/assets/home/2man.png')" alt="history" />
        </div>
        <div class="column is-4-desktop is-half-mobile">
          <v-img :src="require('@/assets/home/2man-2.png')" alt="history" />
        </div>
        <div class="column is-4-desktop is-12-mobile">
          <h4 class="text-xs-h5 text-lg-h4 title-color pb-4">
            HOW IT ALL BEGAN
          </h4>
          <p>
            It was 1927, in Monroe Detroit when two cousins (Edwin Shoemaker and
            Edward Knabusch) designed their first La-Z-Boy recliner. Until this
            date, no one had considered designing a chair that reclined…a
            simple, yet effective concept.
          </p>
          <p>
            At the time, the cousins constructed the chair entirely out of
            timber, with the mechanism allowing the chair to move forward, as
            you pushed the top part of the chair backwards. The chairs
            appearance represented something you would find on your deck or
            patio today and was designed to represent ‘nature's way of
            relaxing’.
          </p>
        </div>
      </div>
      <div class="columns">
        <div class="column is-full">
          <v-img :src="require('@/assets/home/map.png')" alt="map" />
        </div>
      </div>
      <div>
        <h4 class="text-xs-h5 text-lg-h4 title-color pb-4">
          Message from management team
        </h4>
      </div>
      <div class="columns is-mobile is-multiline">
        <div class="column is-half-desktop is-full-mobile">
          <video
            controls
            src="/videos/k.mp4"
            :poster="require('@/assets/home/k.png')"
          />
          <h4 class="text-xs-h5 text-lg-h4 name pb-4">Keith Wilson</h4>
          <p>President International of La-Z-Boy Incorporated</p>
        </div>
        <div class="column is-half-desktop is-full-mobile">
          <video
            controls
            src="videos/dk.mp4"
            :poster="require('@/assets/home/dk.png')"
          />
          <h5 class="text-xs-h5 text-lg-h4 name pb-4">Dhanakorn Kasetrsuwan</h5>
          <p>
            President of La-Z-Boy Thailand Co.,Ltd.<br />President of La-Z-Boy
            Asia Co.,Ltd.
          </p>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  layout: "home",
  data() {
    return {
      time: new Date("2022-01-27 13:45:00 GMT+0700") - new Date(),
    };
  },
  async created() {
    const countdown = await this.$store.dispatch("getCountdown");
    this.time = new Date(countdown.target) - new Date(countdown.now);
  },
};
</script>
<style lang="scss" scoped>
@import "~vuetify/src/styles/styles.sass";
@media #{map-get($display-breakpoints, 'md-and-down')} {
  p,
  h4,
  h5 {
    padding: 8px;
  }
}
</style>